import { useQuery } from 'react-query';

import { OrderService } from '@/services/order.service';

import { AsyncContent, PatientRefillResponse } from '../patient/refill/types';
import { remapResponseRemainingUnits } from '../patient/refill/util';

interface UsePatientRefillQueryOptions {
  onError?: (err: unknown) => void;
}

const QUERY_KEY_PATIENT_REFILL = 'patientRefill';

export const usePatientRefillQuery = (
  userId: number,
  orderId: number,
  options: UsePatientRefillQueryOptions,
): AsyncContent<PatientRefillResponse> => {
  const { onError } = options;

  const { data, isError, isLoading, isSuccess } = useQuery<PatientRefillResponse>({
    queryKey: [QUERY_KEY_PATIENT_REFILL, userId],
    queryFn: async () => {
      const refillResponse = await OrderService.getOrderForPatientRefill(userId, orderId);
      // Workaround for a backend bug
      return remapResponseRemainingUnits(refillResponse.data);
    },
    onError,
  });

  return {
    data,
    isError,
    isLoading,
    isSuccess,
  };
};

export default usePatientRefillQuery;
